import React from "react"
import { graphql } from "gatsby"

// Components
import SEO from "../components/global/seo"
import { PageBlocks } from "../components/pageBlocks"

const StandardPage = ({ data }) => {
  const { title, keywords, metaDescription, main } =
    data.sanityStandardPage || {}
  return (
    <>
      <SEO title={title} keywords={keywords} description={metaDescription} />
      {main && <PageBlocks blocks={main.blocks} />}
    </>
  )
}

// PAGE_QUERY
export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    sanityStandardPage(slug: { current: { eq: $slug } }) {
      title
      metaDescription
      keywords
      main {
        blocks {
          ... on SanityAwards {
          _key
          _type
          heading
          items {
            image {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            text
          }
        }

          ... on SanityPageSpacer {
            _key
            _type
            size
            headerSpacer
          }
          ... on SanityFeatureBanner {
            _key
            _type
            image {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            backgrounds {
              ... on SanityBannerImage {
                _key
                _type
                asset {
                  fluid(maxWidth: 2500) {
                    ...GatsbySanityImageFluid_noBase64
                  }
                }
              }
              ... on SanityBannerVideo {
                _key
                _type
                videoURL
              }
            }
            heading
            links {
              linkText
              pageReference {
                ... on SanityProjectPage {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityStandardPage {
                  id
                  slug {
                    current
                  }
                }
              }
            }
          }
          ... on SanityBioCarousel {
            _key
            _type
            heading
            bios {
              image {
                asset {
                  fluid(maxWidth: 2500) {
                    ...GatsbySanityImageFluid_noBase64
                  }
                }
              }
              name
              position
            }
          }
          ... on SanityTestimonials {
            _key
            _type
            quotes {
              _key
              author
              quote
            }
          }

          ... on SanityStandardText {
            _key
            _type
            wrapped
            measured
            headingMeasure
            text {
              _rawText(resolveReferences: { maxDepth: 5 })
            }
            links {
              linkText
              pageReference {
                ... on SanityProjectPage {
                  id
                  metaDescription
                  slug {
                    current
                  }
                }
                ... on SanityStandardPage {
                  id
                  slug {
                    current
                  }
                }
              }
            }
          }
          ... on SanityImageText {
            _key
            _type
            text {
              _rawText(resolveReferences: { maxDepth: 5 })
            }
            image {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            reverse
          }
          ... on SanityImageCarousel {
            _key
            _type
            images {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityProjectCarousel {
            _key
            _type
            heading
            subheading
            images {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            projectReference {
                projectName
                projectType
                _type
                slug {
                  current
                }
              }
          }
          ... on SanityServiceScroller {
            _key
            _type
            sections {
              _key
              title
              image {
                asset {
                  fluid(maxWidth: 1440) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              text {
                _rawText(resolveReferences: { maxDepth: 5 })
              }
            }
          }
          ... on SanitySingleImage {
            _key
            _type
            align
            image {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityContactDetails {
            _key
            _type
            details {
              _rawText(resolveReferences: { maxDepth: 5 })
            }
          }
          ... on SanityImageGrid {
            _key
            _type
            imageOne {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            imageTwo {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            imageThree {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityEditorialLayout {
            _key
            _type
            text {
              _rawText(resolveReferences: { maxDepth: 5 })
            }
            imageOne {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            imageTwo {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            reverse
          }
          ... on SanityIntroGrid {
            _key
            _type
            text {
              _rawText(resolveReferences: { maxDepth: 5 })
            }
            projectsLink {
              _type
              slug {
                _type
                current
              }
            }
            projects {
              image {
                asset {
                  fluid(maxWidth: 1440) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              projectReference {
                projectName
                projectType
                _type
                slug {
                  current
                }
              }
            }
          }
          ... on SanityProjectGrid {
            _key
            _type
            projects {
              image {
                asset {
                  fluid(maxWidth: 1440) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              gridStart
              gridEnd
              gridAlign
              projectReference {
                projectName
                projectType
                _type
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
  }
`

export default StandardPage
